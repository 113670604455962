<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6">
        <div>
          <template>
            <div>
              <template>
                <v-row dense>
                  <v-col>
                    <v-autocomplete
                      :items="filteredLabs"
                      :filter="customFilter"
                      :rules="rules.required"
                      label="Select a Lab *"
                      no-data-text="No matches were found"
                      item-text="title"
                      item-value="id"
                      :value="value"
                      @input="emitChange"
                      :disabled="disabled"
                      @change="saveToLocalStorage('lab_id')"
                      v-model="lab_id"
                    >
                      <template v-slot:label>
                        <h4>
                          Select a Lab
                          <span style="color: red;">
                            *
                          </span>
                        </h4>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-- <v-row>
  {{ activeSampleShipmentId }}
</v-row> -->

                <!-- <v-row>
  {{ farm_id }}
</v-row> -->

                <!-- <v-row>
  {{ active_sample.farm_id }}
</v-row> 

<v-row>
  {{ active_sample }}
</v-row> 


<v-row>
  {{ active_sample.shipment.id }}
</v-row>


-->

                <!-- <v-row dense>
                  <v-col style="height: 50px;">
                    <v-autocomplete
                      label="Shipment"
                      placeholder="Shipment"
                      v-bind:value="value"
                      v-on:input="setShipmentValue($event)"
                      :items="shipments"
                      item-text="id"
                      item-value="id"
                      :clearable="clearable"
                      no-data-text="No Available Shipments"
                      auto-select-first
                      :rules="rules.required"
                      v-model="shipment_id"
                      @change="saveToLocalStorage('shipment_id')"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          @click="data.select"
                          style="height: 22px !important;"
                        >
                          #{{ data.item.id }}
                        </v-chip>
                      </template>

                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.id"
                          ></v-list-item-title>
                          <v-list-item-subtitle>
                            Shipment Created Date: {{ data.item.created }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Samples in Shipment: {{ data.item.samples.length }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>


                  </v-col>

                </v-row> -->
              </template>
            </div>
          </template>
        </div>

        <!-- <LabSelection v-model="lab_id"  @change="saveToLocalStorage('lab_id')" /> -->
      </v-col>
      <v-col cols="12" sm="6">
        <v-autocomplete
          :items="farms"
          :rules="rules.required"
          label="Farm Name *"
          no-data-text="No farms to select from"
          item-text="title"
          item-value="id"
          v-model="farm_id"
          clearable
          @change="saveToLocalStorage('farm_id')"
        >
          <template v-slot:label>
            <h4>
              Farm Name
              <span style="color: red;">
                *
              </span>
            </h4>
          </template>
        </v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" sm="4" v-if="farm_id">
        <v-autocomplete
          :items="farms"
          :rules="rules.alphanumericRequired"
          label="Farm ID"
          no-data-text="No farms to select from"
          item-text="id"
          item-value="id"
          v-model="farm_id"
        ></v-autocomplete>
      </v-col> -->
      <v-col cols="12" sm="2">
        <v-dialog v-model="farmDialog" persistent>
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">Add Farm</v-btn>
          </template> -->
          <FarmAddDialog v-bind:dialog.sync="farmDialog"></FarmAddDialog>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-autocomplete
          :items="sources"
          label="Feed Sources"
          no-data-text="No feed sources to select from"
          item-text="title"
          item-value="id"
          v-model="farms_source_id"
          clearable
          @change="saveToLocalStorage('farms_source_id')"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" sm="6" v-if="farm_id">
        <v-dialog v-model="sourceDialog" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">Add Source</v-btn>
          </template>
          <SourceAddDialog v-bind:dialog.sync="sourceDialog"></SourceAddDialog>
        </v-dialog>
      </v-col> -->
      <v-col cols="12" sm="6">
        <v-select
          :items="feed_types"
          :rules="rules.required"
          label="Feed Type *"
          no-data-text="No feed types to select from"
          item-text="title"
          item-value="id"
          v-model="feed_type_id"
          :disabled="!!quick_feed_type"
          @change="handleFeedTypeChange"
        >
          <template v-slot:label>
            <h4>
              Feed Type
              <span style="color: red;">
                *
              </span>
            </h4>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <!-- <v-row dense>
      <v-col cols="12" sm="6">
        <v-select
          :items="getQuickFeedTypes"
          label="Quick Feed Types"
          no-data-text="No quick feed types to select from"
          item-text="title"
          item-value="id"
          v-model="quick_feed_type"
          :disabled="!!farms_source_id"
          v-on:input="populateFeedFields"
          return-object
          clearable
        ></v-select>
      </v-col>
    </v-row> -->
    <!-- <v-row dense> -->
    <!-- <v-col cols="12" sm="6">
        <v-select
          :items="feedTypesByClass"
          :rules="rules.required"
          label="Feed Type"
          no-data-text="No feed types to select from"
          item-text="title"
          item-value="id"
          v-model="feed_type_id"
          :disabled="!!quick_feed_type"
        ></v-select>
      </v-col> -->
    <!-- <v-col cols="12" sm="6">
        <v-select
          :items="feed_classes"
          :rules="rules.required"
          label="Feed Category"
          no-data-text="No feed categories to select from"
          item-text="title"
          item-value="id"
          v-model="feed_class_id"
          :disabled="!!quick_feed_type"
          clearable
        ></v-select>
      </v-col> -->
    <!-- </v-row> -->
    <v-row dense>
      <v-col cols="12" sm="12">
        <v-textarea
          v-model="description"
          label="Notes"
          rows="2"
          :rules="rules.description"
          @change="saveToLocalStorage('description')"
        ></v-textarea>
      </v-col>
    </v-row>
    <!-- <v-row dense>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="is_sample_bag"
          label="Sample Bag Barcode"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="is_sample_bag === true" dense>
      <v-col cols="12" sm="4">
        <v-text-field
          ref="bagbarcode"
          v-model="sample_bag_barcode"
          label="Bar Code"
        >
        </v-text-field>
      </v-col>
    </v-row> -->
    <!-- <v-row dense>
      <v-col>
        <v-checkbox label="Is Fresh" v-model="is_fresh" />
      </v-col>
    </v-row> -->
    <!-- <v-row dense>
      <v-col>
        <v-checkbox
          v-model="is_linked"
          label="Is this sample linked to another for testing? (This is only selected if the first sample is already logged.)"
        ></v-checkbox>
      </v-col>
    </v-row> -->
    <v-row v-if="is_linked" dense>
      <v-col>
        <v-alert dense type="info">
          Linked samples include samples logged for apparent nutrient
          digestibility testing, mixer evaluations, duplicate samples, etc.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="is_linked" dense>
      <LoadingBar v-if="loading === true" />
      <v-col>
        <LinkedSamples />
      </v-col>
    </v-row>
    <v-row v-if="selectedClient.is_grand_valley === true" dense>
      <div class="h6 py-2 font-weight-bold">Grand Valley</div>
    </v-row>
    <v-row v-if="selectedClient.is_grand_valley === true" dense>
      <v-col cols="4">
        <v-text-field
          v-model="gv_sales_rep_name"
          label="Sales Rep. Name"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="gv_sales_rep_number"
          label="Sales Rep. Number"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="gv_file_number"
          label="File Number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="selectedClient.is_wallensteins === true" dense>
      <div class="h6 py-2 font-weight-bold">Wallensteins</div>
    </v-row>
    <v-row v-if="selectedClient.is_wallensteins === true" dense>
      <v-col cols="4">
        <v-text-field
          v-model="wallensteins_req_id"
          label="Requester ID"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-dialog v-model="feedTypeReminder" width="500" persistent>
      <v-card>
        <v-card-title>Please Note</v-card-title>
        <v-card-text>
          <!-- <p><b>Sample ID:</b> {{ active_sample.slug }}</p>
            <p>
              <b>Reference Code:</b>
              {{ active_sample.slug ? hexadecimalId : "none" }}
            </p> -->

          <!-- <p>
              {{ active_sample.sample_bag_barcode || "(None)" }}
            </p> -->
          Please note that selecting another feed type will de-select the feed
          source
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="handlleClickOk"
            style="margin-left: auto;"
            color="primary"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import rules from "@/utils/rules";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import LoadingBar from "@/components/loaders/LoadingBar";
// import SourceAddDialog from "@/components/sources/SourceAddDialog";
import LinkedSamples from "@/components/samples/SampleWizardSteps/SampleStepComponents/LinkedSamples";
import { validId } from "@/utils";
import FarmAddDialog from "@/components/farms/FarmAddDialog";
// import LabSelection from "@/components/labs/LabSelection";
import hasIn from "lodash.hasin";

export default {
  name: "GeneralSampleInformation",
  props: {
    value: [String, Number],
    // lab_id: [String, Number],
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialInfo: {
      type: String,
      default: ""
    },
    showCreateNew: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FarmAddDialog,
    // SourceAddDialog,
    LoadingBar,
    LinkedSamples
    // LabSelection
  },
  data() {
    return {
      loading: false,
      feedTypeReminder: false,
      cuttingOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      dateCollected: {
        label: "Date Collected",
        field: "date_collected"
      },
      dateHarvested: {
        label: "Harvest Date",
        field: "date_harvested"
      },
      rules: {
        required: [rules.required],
        alphanumericRequired: [rules.required, rules.alphanumeric],
        description: [rules.maxLength(255)]
      },
      farmDialog: false,
      sourceDialog: false
      // shipments: []
    };
  },
  computed: {
    ...mapFields("sampleWizard", [
      "wizard.feed_class",
      "wizard.quick_feed_type"
    ]),
    ...mapGetters("clients", ["selectedClient"]),
    ...mapFields("samples", [
      "active_sample.farm_id",
      "active_sample.farms_source_id",
      "active_sample.feed_type_id",
      "active_sample.shipment_id",
      "active_sample.description",
      "active_sample.is_preground",
      "active_sample.is_fresh",
      "active_sample.grind_size",
      "active_sample.dry_matter_method",
      "active_sample.grind_method",
      "active_sample.date_collected",
      "active_sample.date_harvested",
      "active_sample.cutting",
      "active_sample.is_linked",
      "active_sample.linked_samples",
      "active_sample.is_sample_bag",
      "active_sample.sample_bag_barcode",
      "active_sample.gv_file_number",
      "active_sample.gv_sales_rep_name",
      "active_sample.gv_sales_rep_number",
      "active_sample.wallensteins_req_id"
    ]),
    ...mapFields("samples", {
      // activeSampleShipmentId: "active_sample.shipment_id"
    }),
    ...mapGetters("baseData", ["labs"]),
    filteredLabs() {
      return this.labs.filter(lab => lab.is_active === true);
    },
    ...mapState("sources", { farmSource: "detailItem" }),
    ...mapGetters("baseData", [
      "filterById",
      "getQuickFeedTypes",
      "feed_types",
      "feed_classes"
    ]),
    ...mapGetters("sources", { sources: "getActiveItems" }),
    ...mapGetters("farms", { farms: "getActiveItems" }),
    feed_class_id: {
      get() {
        // If there's a feed_class defined, use that
        if (validId(this.feed_class)) return this.feed_class;
        // Otherwise, check if a feed_type was selected and use its feed class
        if (this.feed_type_id) {
          const feed_type = this.feed_types.find(
            item => item.id === this.feed_type_id
          );
          if (feed_type) return feed_type.feed_class_id;
        }
        // Otherwise, we don't have a value to return
        return null;
      },
      set(newVal) {
        // When clearing the value, also clear the feed type. This is a filter field after all.
        if (newVal === null) this.feed_type_id = null;
        // Always update the local feed_class, since feed_class_id is computed on the entity
        this.feed_class = newVal;
      }
    },
    feedTypesByClass() {
      return !this.feed_class_id
        ? this.feed_types
        : this.feed_types.filter(
            item => item.feed_class_id === this.feed_class_id
          );
    },
    ...mapFields("samples", [
      // "active_sample.client_id",
      "active_sample.lab_id"
    ]),
    ...mapGetters("clients", ["selectedClient", "hasMultipleClients"]),
    ...mapState("clients", { clients: "items" }),
    ...mapState("samples", ["active_sample"]),
    routeSampleId() {
      return hasIn(this.$route, "params.sampleId")
        ? this.$route.params.sampleId
        : null;
    }
  },
  methods: {
    ...mapActions("sources", ["getFarmSource", "getFarmSources"]),
    ...mapActions("samples", ["getSamples"]),
    ...mapMutations("samples", ["setActiveSampleCopyTo"]),
    customFilter(item, queryText) {
      const title = item.title.toLowerCase();
      const searchText = queryText.toLowerCase();

      return title.indexOf(searchText) > -1;
    },
    emitChange(value) {
      this.$emit("input", value);
    },
    handleFeedTypeChange() {
      if (this.farms_source_id) {
        this.saveToLocalStorage("feed_type_id");
        this.feedTypeReminder = true;
      }
    },
    handlleClickOk() {
      this.feedTypeReminder = false;
      this.farms_source_id = null;
    },
    populateFeedFields(val) {
      if (val) {
        this.feed_class_id = val.feed_class_id;
        this.feed_type_id = val.id;
      } else {
        this.feed_class_id = null;
        this.feed_type_id = null;
      }
    },
    updateDateField(field, value) {
      this[field] = value;
    },
    search(val) {
      return val && val !== this.value && this.loading !== true;
    },
    isLinkedPopulate() {
      if (this.is_linked) {
        this.loading = true;
        // @TODO: this needs to be updated to bring samples into the component that are needed, because samples will be paginated
        this.getSamples().finally(() => {
          this.loading = false;
        });
      } else {
        this.linked_samples = [];
      }
    },
    sampleBagBarcodeFocus() {
      this.$nextTick(() => {
        if (this.$refs.bagbarcode) {
          this.$refs.bagbarcode.focus();
        }
      });
    },
    saveToLocalStorage(key) {
      localStorage.setItem(key, JSON.stringify(this[key]));
    }
    // ...mapActions("shipments", ["getUnshipped"]),
    // setShipmentValue(val) {
    //   this.anyChanges = true;
    //   this.$emit("input", val);
    //   localStorage.setItem("shipmentId", val);
    // },
    // populateShipments() {
    //   this.loading = true;
    //   let params = {};
    //   // Filter by lab_id if one was provided
    //   // if (this.lab_id && parseInt(this.lab_id) > 0) params.lab_id = this.lab_id;
    //   return this.getUnshipped(params).then(({ data }) => {
    //     // Populate the shipments
    //     this.shipments = data.data;
    //     // If a value was not provided
    //     if (!this.value || !(parseInt(this.value) > 0)) {
    //       // and there is a shipment available
    //       if (this.shipments.length) {
    //         // Pre-select the first shipment
    //         this.setShipmentValue(this.shipments[0].id);
    //       }
    //     } else {
    //       // If selected shipment isn't available in the dataset, then we should unset it
    //       const shipment = this.items.find(item => item.id === this.value);
    //       if (shipment === undefined) {
    //         this.setShipmentValue(null);
    //       }
    //     }
    //     this.loading = false;
    //   });
    // },
    // handleClearShipment() {
    //   this.setShipmentValue(null);
    //   this.disabled = true;
    // }
  },
  watch: {
    is_linked: function() {
      this.isLinkedPopulate();
    },
    search(val) {
      return val && val !== this.value && this.loading !== true;
    },
    farm_id(farmId) {
      if (farmId) this.getFarmSources(farmId);
    },
    farms_source_id(farmsSourceId, previousSourceId) {
      if (!farmsSourceId) return;
      this.getFarmSource({
        farm_id: this.farmId,
        source_id: farmsSourceId
      }).then(() => {
        this.quick_feed_type = null;
        this.populateFeedFields({
          feed_class_id: this.farmSource.feed_class_id,
          id: this.farmSource.feed_type_id
        });
        this.farmSource.copy_tos.forEach(copyTo => {
          this.setActiveSampleCopyTo(copyTo.id);
        });
      });
      const previousSource = this.sources.find(
        source => source.id === previousSourceId
      );
      if (
        !this.description ||
        (previousSource && this.description === previousSource.title)
      ) {
        const source = this.sources.find(source => source.id === farmsSourceId);
        this.description = source ? source.title : "";
      }
    },
    is_sample_bag: function() {
      this.sampleBagBarcodeFocus();
    }
    // client_id(clientId) {
    //   const client = this.clients.find(item => item.id === clientId);
    //   if (client && client.lab_id !== this.lab_id) {
    //     this.lab_id = client.lab_id;
    //   } else {
    //     this.getSampleTestComponents();
    //   }
    // },
    // lab_id() {
    //   this.getSampleTestComponents();
    // }
  },
  mounted() {
    this.isLinkedPopulate();
    this.lab_id =
      this.lab_id && this.filteredLabs.some(lab => lab.id == this.lab_id)
        ? this.lab_id
        : this.lab_id === true || this.lab_id === true
        ? null
        : localStorage.getItem("lab_id") &&
          this.filteredLabs.some(
            lab => lab.id == localStorage.getItem("lab_id")
          )
        ? JSON.parse(localStorage.getItem("lab_id"))
        : null;
    this.farm_id =
      this.farm_id && this.farms.some(farm => farm.id == this.farm_id)
        ? this.farm_id
        : localStorage.getItem("farm_id") &&
          this.farms.some(farm => farm.id == localStorage.getItem("farm_id"))
        ? JSON.parse(localStorage.getItem("farm_id"))
        : null;
    // this.farms_source_id =
    //   this.farms_source_id &&
    //   this.sources.some(source => source.id == this.farms_source_id)
    //     ? this.farms_source_id
    //     : localStorage.getItem("farms_source_id") &&
    //       this.sources.some(
    //         source => source.id == localStorage.getItem("farms_source_id")
    //       )
    //     ? JSON.parse(localStorage.getItem("farms_source_id"))
    //     : null;
    this.feed_type_id =
      this.feed_type_id &&
      this.feed_types.some(feed_type => feed_type.id == this.feed_type_id)
        ? this.feed_type_id
        : localStorage.getItem("feed_type_id") &&
          this.feed_types.some(
            feed_type => feed_type.id == localStorage.getItem("feed_type_id")
          )
        ? JSON.parse(localStorage.getItem("feed_type_id"))
        : null;
    this.description = this.description
      ? this.description
      : localStorage.getItem("description")
      ? JSON.parse(localStorage.getItem("description"))
      : null;

    // this.active_sample.shipments[0].id = this.active_sample.shipment_id;
    // this.active_sample.shipment.id = this.active_sample.shipment_id;
    // this.active_sample.shipmentid = this.active_sample.shipment_id;
  }
};
</script>

<style scoped></style>
