<template>
  <v-row>
    <v-col>
      <h3>Frequent Packages and Components</h3>

      <!-- <div>{{ adjusted_test_components }}</div> -->
      <!-- <div>{{ allTestPackages }}</div> -->
      <v-row
        style="height: 100px; overflow-y: auto; margin-top: 20px; display: flex; flex-wrap: wrap; width: 100%;"
      >
        <!-- <v-chip
          v-for="item in this.adjusted_test_components.slice(0, 5)"
          text-color="white"
          color="#006940"
          style="background-color: #006940; color: #fff;"
          :key="item.id"
          class="ma-2"
          @click:close="removeChip(item.id, item.title)"
          >
          {{ item.title }}
        </v-chip> -->
        <v-chip
          v-for="item in this.frequentComponents"
          text-color="white"
          color="#006940"
          style="background-color: gray; color: #fff;"
          :key="item.id"
          class="ma-2"
          @click="handleClickComponents(item)"
        >
          {{ item.title }}
        </v-chip>
        <v-chip
          v-for="item in this.frequentPackages"
          text-color="white"
          color="#006940"
          style="background-color: gray; color: #fff;"
          :key="item.id"
          class="ma-2"
          @click="handleClickPackages(item)"
        >
          {{ item.title }}
        </v-chip>
      </v-row>
      <v-row style="margin-bottom: 3rem;">
        <v-card
          class="p-0 mb-7"
          width="100%"
          flat
          style="width: 99%; display: flex; margin: auto; background-color: #F5F6FA; border-radius: 2px;"
        >
          <!-- <v-toolbar dense style="background-color: #F5F6FA;"> -->
          <v-text-field
            solo
            placeholder="Search packages or components"
            v-model="searchField"
            hide-details
            single-line
            @keydown.enter.prevent
            :append-icon="searchField.length ? 'mdi-close' : 'mdi-magnify'"
            @click:append="clearSearchField"
          ></v-text-field>
          <!-- </v-toolbar> -->
        </v-card>
      </v-row>
      <h3>Selected Packages and Components</h3>

      <v-row
        style="height: 100px; overflow-y: auto; margin-top: 20px; display: flex; flex-wrap: wrap; width: 100%;"
      >
        <v-chip
          v-for="item in this.test_components_proxy"
          text-color="white"
          color="#006940"
          style="background-color: #006940; color: #fff;"
          :key="item.id"
          class="ma-2"
          close
          @click:close="removeChip(item.id, item.title)"
        >
          {{ item.title }}
        </v-chip>
        <v-chip
          v-for="item in this.packageTitles"
          text-color="white"
          color="#006940"
          style="background-color: #006940; color: #fff;"
          :key="item.id"
          class="ma-2"
          close
          @click:close="removePackage(item.id, item.title)"
        >
          {{ item.title }}
        </v-chip>
      </v-row>
      <v-row>
        <v-list-item
          v-if="
            filteredTestPackages.length > 0 || filteredTestComponents.length > 0
          "
        >
          <div style="display: flex; flex-direction: column; width: 100%;">
            <v-data-table
              v-model="test_packages"
              :headers="search_headers"
              :items="filteredTestPackages"
              :disable-pagination="true"
              item-key="id"
              show-select
              :hide-default-footer="true"
              noDataText=""
              @click:row="handleClickPackages"
            >
              <template #header.data-table-select></template>
            </v-data-table>
            <v-data-table
              v-model="test_components_proxy"
              :items="filteredTestComponents"
              :disable-pagination="true"
              :headers="search_component_headers"
              item-key="id"
              show-select
              noDataText=""
              :hide-default-footer="true"
              @click:row="handleClickComponents"
            >
              <template #header.data-table-select></template>
            </v-data-table>
          </div>
        </v-list-item>
      </v-row>
      <v-row>
        <v-col>
          <h3>Test Packages</h3>
          <v-list>
            <v-list-group
              v-for="package_category in test_package_categories"
              :key="package_category.name"
              v-model="package_category.active"
              :prepend-icon="package_category.action"
              no-action
              class="elevation-1"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <!-- <v-list-item-title v-text="getPackageTitleWithCount(
                    package_category.name,
                    package_category.id
                  ).toUpperCase()
                    "></v-list-item-title> -->
                  <v-list-item-title style="height: 17px;">
                    <div
                      style="display: flex; justify-content: space-between; text-transform: uppercase; align-items: center;"
                    >
                      <p>{{ package_category.name }}</p>
                      <p>{{ getPackageCount(package_category.id) }}</p>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item>
                <template>
                  <v-data-table
                    v-model="test_packages"
                    :headers="test_package_headers"
                    :items="getPackageOnCategoryId(package_category.id)"
                    :disable-pagination="true"
                    item-key="id"
                    show-select
                    :hide-default-footer="true"
                    noDataText="There are no test packages to select from."
                    @click:row="handleClickPackages"
                  >
                    <template #header.data-table-select></template>
                  </v-data-table>
                </template>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>
      </v-row>
      <v-row v-if="selectedClient.report_calibrate">
        <v-col>
          <PackageCalibrate />
        </v-col>
      </v-row>
      <v-row v-if="selectedClient.report_grand_valley">
        <v-col>
          <PackageGrandValley />
        </v-col>
      </v-row>
      <v-row v-if="selectedClient.report_best_mix">
        <v-col>
          <PackageBestMix />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Test Components</h3>
          <v-list>
            <v-list-group
              v-for="category in component_categories"
              :key="category.title"
              v-model="category.active"
              :prepend-icon="category.action"
              no-action
              class="elevation-1"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <!-- <v-list-item-title
                    v-text="
                      getTitleWithCount(
                        category.title,
                        category.id
                      ).toUpperCase()
                    "
                  ></v-list-item-title> -->
                  <v-list-item-title style="height: 17px;">
                    <div
                      style="display: flex; justify-content: space-between; text-transform: uppercase; align-items: center;"
                    >
                      <p>{{ category.title }}</p>
                      <p>{{ getCount(category.id) }}</p>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item>
                <template>
                  <v-data-table
                    v-model="test_components_proxy"
                    :headers="headers"
                    :items="test_components_formatted(category.id)"
                    :disable-pagination="true"
                    item-key="id"
                    show-select
                    :hide-default-footer="true"
                    @click:row="handleClickComponents"
                    noDataText="There are no test components to select from."
                  >
                    <template
                      v-slot:item.data-table-select="{
                        isSelected,
                        select,
                        item
                      }"
                    >
                      <v-simple-checkbox
                        :value="isSelected"
                        color="primary"
                        :disabled="item.in_selected_package"
                        @input="select($event)"
                      />
                    </template>
                    <template v-slot:item.package="{ item }">
                      {{
                        item.in_selected_package ? "Included in Package" : ""
                      }}
                    </template>
                    <template v-slot:item.price="{ item }">
                      {{ getComponentStoredPrice(item.id, item.price) }}
                    </template>
                  </v-data-table>
                </template>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import rules from "@/utils/rules";
import { mapGetters, mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import PackageCalibrate from "@/components/samples/SampleWizardSteps/SampleStepComponents/PackageCalibrate";
import PackageBestMix from "@/components/samples/SampleWizardSteps/SampleStepComponents/PackageBestMix";
import PackageGrandValley from "@/components/samples/SampleWizardSteps/SampleStepComponents/PackageGrandValley";

export default {
  name: "PackageSelection",
  components: { PackageCalibrate, PackageBestMix, PackageGrandValley },
  data() {
    return {
      testComponentStoredPricing: [],
      rules: {
        required: [rules.required]
      },
      headers: [
        {
          text: "",
          value: "package"
        },
        {
          text: "Component Name",
          value: "title"
        },
        { text: "Description", value: "description" },
        { text: "Price", value: "price" },
        { text: "International Price", value: "price_international" }
      ],
      test_package_headers: [
        {
          text: "",
          value: "package"
        },
        {
          text: "Package Name",
          value: "title"
        }
      ],
      search_headers: [
        {
          text: "",
          value: "package"
        },
        {
          text: "Package/Component Name",
          value: "title"
        }
      ],
      search_component_headers: [
        {
          text: "",
          value: ""
        },
        {
          text: "",
          value: "title"
        }
      ],
      searchField: "",
      chip1: true
    };
  },
  computed: {
    ...mapFields("samples", [
      "active_sample.test_packages",
      "active_sample.test_components",
      "active_sample.lab_id",
      "adjusted_test_components"
    ]),
    frequentPackages() {
      return this.allTestPackages.filter(item => item.is_quick_package);
    },
    frequentComponents() {
      return this.adjusted_test_components.filter(
        item => item.frequent_components
      );
    },
    ...mapGetters("baseData", [
      "test_package_categories",
      "component_categories"
    ]),
    ...mapState("samples", ["active_sample"]),
    ...mapGetters("samples", ["filterById", "adjusted_test_components"]),
    ...mapGetters("clients", ["selectedClient"]),
    ...mapGetters("baseData", {
      getById: "getById",
      allTestPackages: "test_packages",
      allComponents: "test_components_proxy"
    }),
    packageTitles: {
      get() {
        let titles = [];

        if (this.test_packages.length > 0) {
          titles.push(
            this.getById("test_packages", this.test_packages[0].id) || {}
          );
        }

        if (this.test_packages.length > 1) {
          this.test_packages.slice(1).forEach(test_package => {
            titles.push(this.getById("test_packages", test_package.id) || {});
          });
        }
        return titles;
      },
      set(newVal) {
        this.test_packages = newVal.map(item => ({ id: item.id }));
      }
    },
    test_components_formatted() {
      return test_component_type_id => {
        return this.filterById(
          "adjusted_test_components",
          test_component_type_id,
          "test_component_type_id"
        ).map(item => {
          return {
            ...item,
            in_selected_package: !!this.package_components.find(
              i => i.id === item.id
            )
          };
        });
      };
    },
    test_components_proxy: {
      get() {
        // Return full test component objects
        return this.adjusted_test_components.filter(item =>
          this.test_components.find(
            i =>
              i.id === item.id &&
              !this.package_components.find(i => i.id === item.id)
          )
        );
      },
      set(newVal) {
        // Only include the ID in the destination object
        this.test_components = newVal.map(item => ({ id: item.id }));
      }
    },
    test_package_one: {
      get() {
        return this.test_packages.length ? this.test_packages[0].id : null;
      },
      set(newVal) {
        if (!Array.isArray(newVal)) {
          newVal = newVal ? [{ id: newVal }] : [];
        }
        if (this.test_package_two) {
          newVal.push({ id: this.test_package_two });
        }
        this.test_packages = newVal;
      }
    },
    test_package_two: {
      get() {
        return this.test_packages.length > 1 ? this.test_packages[1].id : null;
      },
      set(newVal) {
        if (!Array.isArray(newVal)) {
          newVal = newVal ? [{ id: newVal }] : [];
        }
        if (this.test_package_one) {
          newVal.unshift({ id: this.test_package_one });
        }
        this.test_packages = newVal;
      }
    },
    package_components() {
      // Return array of test components included in package(s)
      return [
        ...this.adjusted_test_components.filter(
          item =>
            !!item.test_packages.find(
              i =>
                i.id === this.test_package_one || i.id === this.test_package_two
            )
        )
      ];
    },
    filteredTestPackages() {
      const searchTerm = this.searchField.toLowerCase().trim();
      // const searchTerm = (this.$refs.searchField && this.$refs.searchField.value) || "";
      return searchTerm
        ? this.allTestPackages.filter(item => {
            const searchTextLower = searchTerm.toLowerCase();
            // console.log(this.allComponents);
            return item.title.toLowerCase().includes(searchTextLower);
          })
        : [];
    },
    filteredTestComponents() {
      const searchTerm = this.searchField.toLowerCase().trim();
      // const searchTerm = (this.$refs.searchField && this.$refs.searchField.value) || "";
      return searchTerm
        ? this.adjusted_test_components.filter(item => {
            const searchTextLower = searchTerm.toLowerCase();
            // console.log(item);
            return item.title.toLowerCase().includes(searchTextLower);
          })
        : [];
    },
    filteredTestData() {
      const searchTerm = this.searchField.toLowerCase().trim();
      // const searchTerm = (this.$refs.searchField && this.$refs.searchField.value) || "";
      return searchTerm
        ? this.adjusted_test_components
            .concat(this.allTestPackages)
            .filter(item => {
              const searchTextLower = searchTerm.toLowerCase();
              // console.log(item);
              return item.title.toLowerCase().includes(searchTextLower);
            })
        : [];
    },
    combinedtestComponentsAndPackages: {
      get() {
        return this.test_packages.concat(this.test_components_proxy);
      }
      // set(newVal) {
      //   // Only include the ID in the destination object
      //   this.test_components.concat(this.test_packages) = newVal.map(item => ({ id: item.id }));
      // }
    }
  },
  methods: {
    ...mapActions("baseData", ["getCalibrateIngredients"]),
    clearSearchField() {
      this.searchField = "";
    },
    getTitleWithCount(title, catId) {
      const filterByCatId = item => item.test_component_type_id === catId;
      const packageCount = this.package_components.filter(filterByCatId).length;
      const selectedCount = this.test_components_proxy.filter(filterByCatId)
        .length;
      return (
        title +
        (packageCount > 0 ? ` [ ${packageCount} Included in Package ]` : "") +
        (selectedCount > 0 ? ` [${selectedCount} Selected ]` : "")
      );
    },
    getCount(catId) {
      const filterByCatId = item => item.test_component_type_id === catId;
      const packageCount = this.package_components.filter(filterByCatId).length;
      const selectedCount = this.test_components_proxy.filter(filterByCatId)
        .length;
      return (
        // title +
        (packageCount > 0 ? ` [ ${packageCount} Included in Package ]` : "") +
        (selectedCount > 0 ? ` [ ${selectedCount} Selected ]` : "")
      );
    },
    getPackageTitleWithCount(title, catId) {
      let selectedIds = this.test_packages.map(a => a.id);
      const selectedCount = this.getPackageOnCategoryId(catId).filter(
        item =>
          item.test_package_category_id === catId &&
          selectedIds.includes(item.id)
      ).length;
      // console.log(this.adjusted_test_components);

      return (
        title + (selectedCount > 0 ? ` [ ${selectedCount} Selected ]` : "")
      );
    },
    getPackageCount(catId) {
      let selectedIds = this.test_packages.map(a => a.id);
      const selectedCount = this.getPackageOnCategoryId(catId).filter(
        item =>
          item.test_package_category_id === catId &&
          selectedIds.includes(item.id)
      ).length;
      // console.log(this.adjusted_test_components);
      // console.log(this.test_components_proxy);

      // console.log(this.test_packages[0]);
      // console.log(this.test_components[0]);
      // console.log(this.packageTitles);

      return selectedCount > 0 ? ` [ ${selectedCount} Selected ]` : "";
    },
    getSelectedPackagesList() {
      let selectedPackages = this.test_packages;
      let selectedComponents = this.test_components_proxy;
      // console.log(selectedPackages.concat(selectedComponents));
      // console.log(this.allTestPackages);
      return selectedPackages.concat(selectedComponents);
    },
    // If a lab limit has been set and this samples lab exists is not limited by the package, return package
    getLabLimitedPackages() {
      let results = this.allTestPackages.filter(item => {
        if (
          item.labs.length === 0 ||
          item.labs.filter(lab => lab.id === this.lab_id).length > 0
        ) {
          return item;
        }
      });
      return results;
    },
    getPackageOnCategoryId(category_id) {
      return this.getLabLimitedPackages().filter(item => {
        if (item.test_package_category_id === category_id) {
          return item;
        }
      });
    },
    getComponentStoredPrice(testComponentId, price) {
      this.test_components.forEach(function(testComponent) {
        if (testComponent.id === testComponentId) {
          if (testComponent._joinData) {
            price = testComponent._joinData.price;
          }
        }
      });
      return price;
    },
    handleClickPackages(row) {
      // console.log(row.id);
      if (this.test_packages.some(e => e.id === row.id)) {
        this.packageTitles = this.packageTitles.filter(e => e.id !== row.id);
      } else {
        this.test_packages.push({ ...row });
      }
      // console.log(this.packageTitles.some(e => e.id === row.id));
    },
    // handleClickRecent(chip) {
    //   console.log(chip);
    //   if (this.test_packages.some(e => e.id === chip)) {
    //     this.packageTitles = this.packageTitles.filter(e => e.id !== chip);
    //   } else {
    //     this.test_packages.push({ ...row });
    //   }
    //   console.log(this.packageTitles.some(e => e.id === row.id));
    // },
    handleClickComponents(row) {
      if (this.package_components.find(i => i.id === row.id)) return;
      // console.log(row);
      if (this.test_components_proxy.some(e => e.id === row.id)) {
        this.test_components_proxy = this.test_components_proxy.filter(
          e => e.id !== row.id
        );
      } else {
        this.test_components.push({ ...row });
      }
      // console.log(this.test_components.some(e => e.id === row.id));
    },
    removeChip(id, title) {
      // console.log(this.allComponents);

      // this.test_packages = this.test_packages.filter(e => e.id !== id);
      // this.test_components_proxy = this.test_components_proxy.filter(
      //   e => e.id !== id
      // );
      const packageToRemove = this.test_packages.find(
        e => e.id === id && e.title === title
      );

      if (packageToRemove) {
        this.test_packages = this.test_packages.filter(
          e => e !== packageToRemove
        );
      } else {
        const componentToRemove = this.test_components_proxy.find(
          e => e.id === id && e.title === title
        );
        if (componentToRemove) {
          this.test_components_proxy = this.test_components_proxy.filter(
            e => e !== componentToRemove
          );
        } else {
          console.warn(
            `Package or component with ID ${id} and title "${title}" not found.`
          );
        }
      }
    },
    removePackage(id, title) {
      // console.log(this.allComponents);

      // this.test_packages = this.test_packages.filter(e => e.id !== id);
      // this.test_components_proxy = this.test_components_proxy.filter(
      //   e => e.id !== id
      // );
      const packageToRemove = this.packageTitles.find(
        e => e.id === id && e.title === title
      );

      if (packageToRemove) {
        this.packageTitles = this.packageTitles.filter(
          e => e !== packageToRemove
        );
        this.test_packages = this.test_packages.filter(
          e => e !== packageToRemove
        );
      }
    }
  },
  watch: {
    package_components(newVal) {
      // Always make sure package components are de-selected
      const ids = newVal.map(item => item.id);
      this.test_components_proxy = this.test_components_proxy.filter(
        item => ids.indexOf(item.id) === -1
      );
    }
    // test_packages(val, oldVal) {
    //   if (this.test_packages.length > 2) {
    //     // Remove the new item
    //     let difference = val.filter(x => !oldVal.includes(x));
    //     if(difference.length > 0) {
    //       const index = this.test_packages.findIndex(testPackage => {
    //         return testPackage.id === difference[0].id;
    //       });
    //       this.test_packages.splice(index, 1);
    //     }
    //   }
    // }
  },
  mounted() {
    // this.active_sample.shipment.id = this.shipment_id;
    // this.active_sample.shipments[0].id = this.shipment_id;
  }
};
</script>

<style lang="scss" scoped>
.v-application--is-ltr .v-list-group--no-action {
  & > .v-list-group__items > .v-list-item {
    padding: 0;
  }
}

.v-data-table {
  width: 100%;
}
</style>
