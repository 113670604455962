<template>
  <div>
    <CopyToSelect />
    <CopyToInvite :inviteCompleteCb="inviteCompleteCb" />
  </div>
</template>

<script>
import CopyToInvite from "@/components/copy_to/CopyToInvite";
import CopyToSelect from "@/components/samples/SampleWizardSteps/SampleStepComponents/CopyToSelect";
import { mapFields } from "vuex-map-fields";
import { mapMutations } from "vuex";

export default {
  name: "CopyTo",
  components: { CopyToInvite, CopyToSelect },
  data() {
    return {};
  },
  computed: {
    ...mapFields("samples", ["active_sample.copy_to"])
  },
  methods: {
    ...mapMutations("samples", ["setActiveSampleCopyTo"]),
    inviteCompleteCb: function(id) {
      this.setActiveSampleCopyTo(id);
    }
  }
};
</script>
