<template>
  <LoadingBar v-if="loading === true" />
  <div v-else>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-text-field
          :value="billingAccount.title"
          label="Business Name"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field disabled :value="fullName" label="Name"></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-text-field
          disabled
          label="Address 1"
          :value="billingAccount.address_1"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          disabled
          label="Address 2"
          :value="billingAccount.address_2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-text-field
          disabled
          label="City"
          :value="billingAccount.city"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          disabled
          v-if="billingAccount.state_id"
          label="State, Region or Province"
          :value="getById('states', billingAccount.state_id, 'id').title"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-text-field
          disabled
          label="Country"
          :value="
            getById('countries', billingAccount.country_id, 'id').country_name
          "
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          disabled
          label="Postal Code"
          :value="billingAccount.postal_code"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-text-field
          disabled
          label="Phone Number"
          :value="billingAccount.work_phone"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field disabled label="Email" :value="firstEmail"></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-checkbox
          label="Billing account information is correct"
          :rules="rules.verifyBilling"
          v-model="billingAgree"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-alert border="top" colored-border type="info" elevation="2">
          If this billing information is not correct,
          <a :href="mailTo">click here</a> to send e-mail to:
          office@foragelab.com
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoadingBar from "@/components/loaders/LoadingBar";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "@/utils/rules";
import isString from "lodash.isstring";

export default {
  name: "VerifyBilling",
  components: { LoadingBar },
  data() {
    return {
      loading: false,
      rules: {
        verifyBilling: [rules.required]
      }
    };
  },
  computed: {
    ...mapState("samples", ["billingAccount"]),
    ...mapGetters("samples", ["firstEmail"]),
    ...mapGetters("baseData", ["getById"]),
    ...mapFields("sampleWizard", ["wizard.billingAgree"]),
    fullName() {
      return (
        this.billingAccount.first_name + " " + this.billingAccount.last_name
      );
    },
    mailTo() {
      return (
        "mailto:office@foragelab.com?subject=The billing information for account #" +
        this.billingAccount.client_id +
        " is incorrect.  Please verify the information."
      );
    }
  },
  methods: {
    ...mapActions("samples", ["getBillingInformation"]),
    ...mapMutations("sampleWizard", ["setError"])
  },
  mounted() {
    this.loading = true;
    this.getBillingInformation()
      .then(() => {
        this.billingAgree = true;
        this.loading = false;
      })
      .catch(e => {
        // If an error is a string, set it as the error message for the wizard
        this.setError(isString(e) ? e : "An unknown error occurred.");
        // Log to console if this was a non-string error
        if (!isString(e)) console.error(e);
      });
  }
};
</script>
